import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  isGranted: boolean;
  isTokenInDatabase: boolean;
  notificationSupport: boolean;
  token: string;

  private userDoc: AngularFirestoreDocument;
  userDocItem: Observable<any>;
  updateState = true;

  userDocData: any;

  data = {
    name: '',
    email: '',
    companyName: '',
    country: null
  };

  myFormGroup = new FormGroup({
    nameFormControl: new FormControl('', Validators.required),
    email: new FormControl('', Validators.email),
    dateFormControl: new FormControl(''),
    companyFormControl: new FormControl(''),
    countryFormControl: new FormControl(''),
  });

  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    private afMessaging: AngularFireMessaging
  ) {
    authService.user.subscribe((user) => {
      if (user) {
        console.log(`User: ${user.phoneNumber}`);
        this.userDoc = this.afs.doc(`users/${user.phoneNumber}`);
        this.userDocItem = this.userDoc.valueChanges();
        this.userDocItem.subscribe((data) => {
          this.userDocData = data;
          console.log(`data.name: ${data.name}`);
          console.log(`data.companyName: ${data.companyName}`);
          console.log(`data.country: ${data.country}`);
          this.data.name = data.name;
          this.data.email = data.email;
          this.data.companyName = data.companyName;
          this.data.country = data.country;

          this.myFormGroup.patchValue({
            nameFormControl: data.name || '',
            email: data.email || '',
            companyFormControl: data.companyName || '',
            countryFormControl: data.country || null
          });

        });
      }
    });
  }

  ngOnInit() {

    this.onChanges();
    this.afMessaging.messages
      .subscribe((message) => { console.log("WOW! New Push Message: ", message); });
    // this.afMessaging.getToken.subscribe(data => {
    //   console.log(`getToken: ${data}`);
    // });

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop and mobile notification");
      this.notificationSupport = false;
    } else {
      this.notificationSupport = true;
      console.log("This browser support desktop and mobile notification");
      if (Notification.permission === "granted") {
        console.log('Notification.permission: ', Notification.permission);
        // const notification = new Notification("Hi there!", {requireInteraction: true});
        // notification.addEventListener('show', () => console.log("show notification"));
        // notification.addEventListener('close', () => console.log("close notification"));
        // console.log("notification: ", notification);
        this.isGranted = true;
        this.isTokenInDB().then( () => {
          this.afMessaging.tokenChanges.subscribe( async (data) => {
            console.log('tokenChanges data: ', data);

            // delete previous token and get-save new token
            if (this.token !== data) {
              this.requestPermission();
            }
          });
        });


      } else {
        this.isGranted = false;
      }
    }

    // console.log('Notification.permission: ', Notification.permission);
  }

  async isTokenInDB() {

    const token = await this.afMessaging.getToken.toPromise();
    this.token = token;

    const documentSnapshot = await this.afs.doc(`users/${this.authService.phoneNumber}/topics/main`).get().toPromise();
    const docRef = documentSnapshot.ref;

    if (!documentSnapshot.data()) {
      console.log("document doesn't exist");
      return;
    } else if (documentSnapshot.data().tokens) {
      const tokens: string[] = documentSnapshot.data().tokens;

      if (tokens.indexOf(this.token) !== -1) {
        this.isTokenInDatabase = true;
      }
    }


  }

  async deleteToken() {
    {
      const documentSnapshot = await this.afs.doc(`users/${this.authService.phoneNumber}/topics/main`).get().toPromise();
      const docRef = documentSnapshot.ref;
      if (!documentSnapshot.data()) {
        return;
      }
      const tokens: string[] = documentSnapshot.data().tokens || [];

      if (tokens.length > 0 && tokens.indexOf(this.token) !== -1) {
        tokens.splice(tokens.indexOf(this.token), 1);
        this.saveTokensDoc(tokens, docRef)
          .then(() => {
            this.isTokenInDatabase = false;
          });
      } else {
        console.log("tokens arr is empty or token not in this array");
      }

    }
  }

  requestPermission() {
    // this.afMessaging.requestPermission
    //   .pipe(mergeMapTo(this.afMessaging.tokenChanges))
    //   .subscribe(
    //     (token) => { console.log('Permission granted! Save to the server!', token); },
    //     (error) => { console.error(error); },
    //   );
    this.afMessaging.requestToken
      .subscribe(
        async (token) => {
          // if (this.token) {
          //   console.log('Delete previous token');
          //   await this.deleteToken();
          // }
          this.token = token;
          this.isGranted = true;
          this.saveToken(token);
          console.log('Permission granted! Save to the server!', token);
          // this.afMessaging.messages
          //   .subscribe((message) => { console.log("WOW! New Push Message: ", message); });
        },
        (error) => { console.error(error); },
      );
  }

  onChanges() {

    this.myFormGroup.valueChanges.subscribe(val => {
      // console.log(val);
      // console.log(data.name);
      if (
        (val.nameFormControl === this.data.name) &&
        (val.email === this.data.email) &&
        (val.companyFormControl === this.data.companyName) &&
        (val.countryFormControl === this.data.country)
        ) {
        this.updateState = true;
      } else {
        this.updateState = false;
      }
    });

  }

  async saveToken(token: string) {
    const data = await this.afs.doc(`users/${this.authService.phoneNumber}/topics/main`).get().toPromise();
    const docRef = data.ref;
    console.log('await data: ', data);
    console.log('exists: ', data.exists);
    console.log('data.data(): ', data.data());
    if (data.exists && data.data().tokens && data.data().tokens.length > 0) {
      // console.log("data.exists && data.data().tokens && data.data().tokens.length > 0");
      const tokens = data.data().tokens;
      if (tokens.indexOf(token) === -1) {
        tokens.push(token);
        console.log('tokens: ', tokens);
        this.saveTokensDoc(tokens, docRef).then(() => {
          this.isTokenInDatabase = true;
        });
      } else {
        console.log('tokens alredy in array: ', tokens);
        console.log('This token alredy in array.');
      }
    } else {
      // console.log("else");
      const tokens = [];
      tokens.push(token);
      console.log('tokens: ', tokens);
      this.saveTokensDoc(tokens, docRef).then(() => {
        this.isTokenInDatabase = true;
      });
    }
  }

  saveTokensDoc(tokens: string[], docRef: firebase.firestore.DocumentReference, topicName: string = "main") {
    return docRef.set(
      {
        topicName: topicName,
        tokens: tokens
      },
      { merge: true }
    );
  }

  saveProfile() {
    this.afs.doc(`users/${this.authService.phoneNumber}`).update({
      name: this.myFormGroup.value.nameFormControl,
      email: this.myFormGroup.value.email || null,
      companyName: this.myFormGroup.value.companyFormControl,
      country: this.myFormGroup.value.countryFormControl || null,
      // birthday: this.myFormGroup.value.dateFormControl,
    }).then(() => {
      this.updateState = true;
    });
  }

}
