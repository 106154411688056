import { Component, OnInit, Input, Inject } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { ROLES } from 'src/app/models/roles.model';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


import * as firebase from 'firebase/app'; // for use firebase.firestore.FieldValue.serverTimestamp()

interface Role {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  selectDisabled = false;

  roleValue: number;

  roles = ROLES;

  userRoleDoc: firebase.firestore.DocumentData | boolean = false;

  @Input() userDoc: any;

  userPhone: string;
  // userDoc: any;

  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    public dialog: MatDialog
  ) { }

  openDialog(role: Role): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      data: { role }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      // this.animal = result;
      if (result) {
        console.log("Sending request");
        this.sendRequest(role);
      } else {
        console.log("Request revoke");
      }
    });
  }

  ngOnInit() {
    console.log("userRoleDoc now: ", this.userRoleDoc);
    this.userPhone = this.authService.phoneNumber;
    this.afs.doc(`users/${this.userPhone}/role/doc`)
      .snapshotChanges().subscribe(
        (data) => {
          // console.log("Doc: ", doc);
          if (data.payload.exists) {
            this.userRoleDoc = data.payload.data();
            console.log(this.userRoleDoc);

            // get current roleValue: number
            this.roleValue = this.userRoleDoc.role.details.value;

            console.log("userRoleDoc now: ", this.userRoleDoc);
          } else {
            this.userRoleDoc = null;
            console.log("userRoleDoc now: ", this.userRoleDoc);
          }
        });

    // .then(doc => {
    //       if (doc.exists) {
    //         this.userRoleDoc = doc.data();
    //         console.log(this.userRoleDoc);
    //       }
    //     })
    // .catch(err => console.log(err));
    // this.userDoc = this.authService.userDoc;
  }

  roleChangeRequest() {

    // find role in roles: {}[] for current select value
    const role = (function (roles: Role[], roleValue: number) {

      let find: Role;
      roles.some((el: Role, index) => {
        console.log(`start for index: ${index} `, el);
        if (el.value === roleValue) {
          console.log('el: ', el);
          find = el;
          return true;
        }
      });
      return find;
    })(this.roles, this.roleValue);

    console.log(`Find role obj: ${JSON.stringify(role)}`);
    console.log(this.roleValue);

    this.openDialog(role);

    // if (confirm(`Отправить запрос на смену доступа? Новый доступ: ${role.viewValue}.`)) {
    //   this.sendRequest(role);
    // }
  }

  sendRequest(role: Role) {
    this.afs.doc(`users/${this.authService.phoneNumber}`).update({
      pending: true
    })
    .then(data => {
      console.log("Update user document, add pending: true ", data);
    })
    .catch(err => console.log(err));

    this.afs.doc(`roleChangeRequests/${this.authService.phoneNumber}`).set(
      {
        creator: this.authService.phoneNumber,
        role: role,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      },
      { merge: true }
    )
      .then(data => {
        console.log("Add or update request document: ", data);
        this.selectDisabled = true;
      })
      .catch(err => console.log(err));
  }

}

@Component({
  selector: 'app-dialog-overview-dialog',
  templateUrl: 'dialog.component.html',
})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  // onNoClick(): void {
  //   this.dialogRef.close();
  // }

}
