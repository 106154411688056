import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from '../auth.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { COUNTRY_NAME } from '../models/country_name.model';
import { LIST } from '../models/stoplistroles.model';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  COUNTRY_LIST = COUNTRY_NAME;
  ROLES = LIST;

  initStopListHeader = true;

  loaded = false;

  previousTitle: string;
  componentTitle = "Стоп-лист НАПОР";

  stoplist: Observable<{}[]>;

  counter = 0;

  displayedColumns: string[] = ['name', 'comment', 'creatorPhone', 'editButton'];

  passportForSearch: string;
  surnameForSearch: string;
  phoneForSearch: string;
  passportNotFound: boolean;
  surnameNotFound: boolean;

  searchResults: {}[];

  colRef: AngularFirestoreCollection<any>;

  myFormGroup = new FormGroup({
    countryFormControl: new FormControl(''),
    roles: new FormControl(''),
  });

  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    private titleService: Title
  ) { }

  manufactureFiler(element) {
    if (element.approved === false) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit() {
    this.previousTitle = this.titleService.getTitle();
    this.titleService.setTitle(this.componentTitle);

    this.colRef = this.afs.collection('stoplist', ref => ref.orderBy('createdAt', 'desc').limit(10));

    this.stoplist = this.colRef.snapshotChanges().pipe(
      map(actions => {
        // this.counter = actions.length;
        console.log('Get new data: ', actions.length);
        // this.counter = 0;
        const result = actions.map(a => {
          const data = a.payload.doc.data();
          const docId = a.payload.doc.id;
          return { docId, ...data };
        }).filter(this.manufactureFiler);

        this.counter = result.length;
        this.loaded = true;
        return result;
      })
    );

    this.onChanges();
  }

  onChanges() {

    this.myFormGroup.get('roles').valueChanges.subscribe((val) => {

      this.initStopListHeader = false;
      this.loaded = false;

      this.stoplist = this.afs.collection('stoplist', ref => ref.where('jobType', '==', val))
        .snapshotChanges().pipe(
          map(actions => {
            // this.counter = actions.length;
            console.log('Get new data: ', actions.length);
            // this.counter = 0;
            const result = actions.map(a => {
              const data = a.payload.doc.data();
              const docId = a.payload.doc.id;
              return { docId, ...data };
            }).filter(this.manufactureFiler);

            this.counter = result.length;
            this.loaded = true;
            return result;
          })
        );

    });

    this.myFormGroup.get('countryFormControl').valueChanges.subscribe(val => {
      console.log(val);
      // console.log(data.name);
      if (val === 'all') {
        this.loaded = false;
        this.loadFullStoplist();

      } else {

        this.initStopListHeader = false;
        this.loaded = false;

        this.stoplist = this.afs.collection('stoplist', ref => ref.where('country', '==', val))
          .snapshotChanges().pipe(
            map(actions => {
              // this.counter = actions.length;
              console.log('Get new data: ', actions.length);
              // this.counter = 0;
              const result = actions.map(a => {
                const data = a.payload.doc.data();
                const docId = a.payload.doc.id;
                return { docId, ...data };
              }).filter(this.manufactureFiler);

              this.counter = result.length;
              this.loaded = true;
              return result;
            })
          );
      }
    });

  }

  loadFullStoplist() {

    this.initStopListHeader = false;
    this.loaded = false;

    this.stoplist = this.afs.collection('stoplist', ref => ref.orderBy('createdAt', 'desc'))
      .snapshotChanges().pipe(
        map(actions => {
          // this.counter = actions.length;
          console.log('Get new data: ', actions.length);
          // this.counter = 0;
          const result = actions.map(a => {
            const data = a.payload.doc.data();
            const docId = a.payload.doc.id;
            return { docId, ...data };
          }).filter(this.manufactureFiler);

          this.counter = result.length;
          this.loaded = true;
          return result;
        })
      );
  }

  // searchByName() {
  //   console.log(`Search for: ${this.nameForSearch}`);
  //   this.afs.collection('stoplist', ref => ref.where('name', '==', this.nameForSearch.trim()))
  //     .valueChanges()
  //     .subscribe((data) => {
  //       console.log(data);
  //       this.searchResults = data;
  //     });
  // }

  searchByPassport() {
    console.log(`Search for: ${this.passportForSearch}`);
    this.afs.collection('stoplist', ref => ref.where('passport', '==', this.passportForSearch.replace(/ /g, '')))
      .valueChanges()
      .subscribe((data) => {
        console.log(data);
        if (data.length > 0) {
          this.passportNotFound = false;
          this.searchResults = data;
        } else {
          this.searchResults = [];
          this.passportNotFound = true;
        }
      });
  }

  searchBySurname() {
    console.log(`Search for: ${this.surnameForSearch}`);
    this.afs.collection('stoplist', ref => ref.where('nameArr', 'array-contains', this.surnameForSearch.trim()))
      .snapshotChanges().pipe(
        map(actions => {
          // this.counter = actions.length;
          // console.log('Actions: ', actions);
          // this.counter = 0;
          const results = actions.map(a => {
            const data = a.payload.doc.data();
            const docId = a.payload.doc.id;
            return { docId, ...data };
          });

          // console.log('results: ', result);
          // this.searchResults = result;

          return results;
        })
      ).subscribe((data) => {
        console.log(data);
        this.surnameNotFound = data.length === 0 ? true : false;
        console.log('this.surnameNotFound: ', this.surnameNotFound);
        this.searchResults = data;
      });
    // .valueChanges()
    // .subscribe((data) => {
    //   console.log(data);
    //   if (data.length > 0) {
    //     this.passportNotFound = false;
    //     this.searchResults = data;
    //   } else {
    //     this.searchResults = [];
    //     this.passportNotFound = true;
    //   }
    // });
  }

  searchByPhone() {
    this.searchResults = [];

    console.log(`Search for: ${this.phoneForSearch}`);
    const regExp = /[\s()-]/gi;

    let phoneString = this.phoneForSearch.replace(regExp, '');
    phoneString = phoneString.slice(-7);

    console.log('Search for last 5 chars: ', phoneString);
    this.afs.collection('stoplist')
      .valueChanges()
      .subscribe((data) => {
        // console.log(data);
        if (data.length > 0) {
          data.forEach((element: any) => {

            if (element.phone) {

              let phone = element.phone.replace(regExp, '');
              phone = phone.slice(-7);

              if (phone === phoneString) {
                this.searchResults.push(element);
              }
            }

            if (element.phone1) {

              let phone = element.phone1.replace(regExp, '');
              phone = phone.slice(-7);

              if (phone === phoneString) {
                this.searchResults.push(element);
              }
            }

            if (element.phone2) {

              let phone = element.phone2.replace(regExp, '');
              phone = phone.slice(-7);

              if (phone === phoneString) {
                this.searchResults.push(element);
              }
            }

          });
          this.passportNotFound = false;
          // this.searchResults = data;
        } else {
          this.searchResults = [];
          this.passportNotFound = true;
        }
      });
  }

  reset() {
    this.passportForSearch = '', this.searchResults = [], this.passportNotFound = false;
    this.surnameNotFound = false;
    this.surnameForSearch = '';
    this.phoneForSearch = '';
  }

  clear() {
    this.searchResults = [], this.passportNotFound = false;
  }

  ngOnDestroy() {
    this.titleService.setTitle(this.previousTitle);
  }

}
