import { Component, OnInit, Input } from '@angular/core';
import { User } from '../models/user.model';
import { AuthService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  private _creatorPhone: string;

  @Input()
  set creatorPhone(creatorPhone: string) {
    this._creatorPhone = creatorPhone;
    this.check();
  }

  @Input() docId: string;
  showDeleteButton = false;

  constructor(
    public authService: AuthService,
    private afs: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  check() {
    if (this.authService.phoneNumber === this._creatorPhone) {
      this.showDeleteButton = true;
    }
  }

  delete() {
    if (confirm('Вы подтверждаете удаление записи?')) {
      this.afs.doc(`stoplist/${this.docId}`).delete()
        .then((data) => {
          console.log(`Document with id: ${this.docId} deleted.`, data);
        })
        .then((err) => {
          console.log(err);
        });
    }
  }

}
