import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shorten'
})
export class PassportShortenPipe implements PipeTransform {
    transform(value: string, strLength: number = 4) {
        if (value.length > strLength) {
            return value.substr(0, strLength) + '...';
        }
        return value;
    }
}
