import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth.service';
import { Observable } from 'rxjs';
import { MatButton } from '@angular/material';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {

  @ViewChild('saveButton') saveButton: MatButton;
  docObserver: Observable<firebase.firestore.DocumentSnapshot>;
  // doc: firebase.firestore.DocumentSnapshot;

  memberForm = this._fb.group({
    organization: ['', Validators.required],
    inn: ['', Validators.required],
    tel: ['', Validators.required],
    city: ['', Validators.required],
    description: [''],
    // id: ['', Validators.required],
  });


  constructor(
    private _fb: FormBuilder,
    private _afs: AngularFirestore,
    private _auth: AuthService) { }

  ngOnInit() {
    this.docObserver = this._afs.doc(`users/${this._auth.phoneNumber}/member/document`).get();
    this.docObserver.subscribe((doc) => {
      if (doc.exists) {
        this.memberForm.patchValue(doc.data());
      }
    });
  }

  onSaveMember() {
    // this.saveButton.nativeElement.disabled = true;
    this.saveButtonState('off');

    console.log('save member');
    console.log(this.memberForm.value);
    // const doc = {
    //   organization: this.memberForm.get('organization').value
    // };
    const doc = this.memberForm.value;
    console.log(doc);

    // add field to doc object for search in the future
    doc.cityFieldForSearch = doc.city.toLowerCase().trim();

    this._afs.doc(`users/${this._auth.phoneNumber}/member/document`)
      .set(doc, { merge: true }).then( () => this.saveButtonState('on'));
  }

  saveButtonState(state: string) {
    const hostElement: HTMLButtonElement = this.saveButton._getHostElement();
    if (state === "off") {
      this.saveButton.disabled = true;
      hostElement.innerHTML = 'Сохранeние...';
    }

    if (state === "on") {
      this.saveButton.disabled = false;
      hostElement.innerHTML = 'Сохранить';
    }
  }

}
