export const ROLES = [
  {
    value: 1,
    viewValue: 'Стандарт'
  },
  {
    value: 3,
    viewValue: 'Монтажник'
  },
  {
    value: 2,
    viewValue: 'Производитель'
  },
  {
    value: 11,
    viewValue: 'Руководитель'
  },
  {
    value: 12,
    viewValue: 'Участник НАПОР'
  },
];

