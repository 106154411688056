export const LIST: string[] = [
    'монтажник',
    'замерщик',
    'дилер',
    'производство',
    'оператор колл-центра',
    'оператор выставочного зала',
    'раскройщик',
    'гарпунщик',
    'маркетолог'
];
