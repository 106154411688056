/// <reference types="@types/googlemaps" />

import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as firebase from 'firebase/app'; // for use firebase.firestore.FieldValue.serverTimestamp()

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';

import { MapsAPILoader } from '@agm/core';

import { LIST } from '../models/stoplistroles.model';
import { COUNTRY_NAME } from '../models/country_name.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {

  @ViewChild('autocompleteInputElement') autocompleteInputElement: ElementRef;

  showForm = true;

  COUNTRY_LIST = COUNTRY_NAME;

  showManufacture = false;

  nameControlPlaceholder = "ФИО";
  nameControlHint = "Иванов Иван Иванович";
  wasNameControlPlaceholder: string;
  wasNameControlHint: string;

  stopItemPlace: {
    address_components: google.maps.GeocoderAddressComponent[],
    formatted_address: string,
    name: string,
    nameLowerCase: string,
    place_id: string
  };

  rolesList: string[] = LIST;
  morePhones = false;
  addCity = false;
  newDocId: string;
  jobType: string;

  myFormGroup = new FormGroup({
    nameFormControl: new FormControl('', Validators.required),
    ogrn_or_inn: new FormControl(''),
    roleFormControl: new FormControl('', Validators.required),
    // passportFormControl: new FormControl(''),
    phoneFormControl: new FormControl(''),
    phoneFormControl1: new FormControl(''),
    phoneFormControl2: new FormControl(''),
    commentFormControl: new FormControl('', Validators.required),
    countryFormControl: new FormControl('', Validators.required),
    cityFormControl: new FormControl(''),
  });

  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router
  ) { }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const options = {
        types: ['(cities)'],
        // componentRestrictions: { country: ['ru', 'by', 'kz', 'ua'] }
        // bounds: defaultBounds,
        // strictBounds: true,
      };
      // this.searchElementRef.nativeElement
      const autocomplete = new google.maps.places.Autocomplete(this.autocompleteInputElement.nativeElement, options);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // this.place = place;
          console.log(place);

          this.stopItemPlace = {
            address_components: place.address_components || null,
            formatted_address: place.formatted_address || null,
            name: place.name || null,
            nameLowerCase: place.name ? place.name.toLowerCase() : null,
            place_id: place.place_id,
          };

          console.log(this.stopItemPlace);

          // this.data.address.adr_address = place.adr_address;
          // this.data.address.formatted_address = place.formatted_address;
          // this.data.address.geometry = {
          //   lat: place.geometry.location.lat(),
          //   lng: place.geometry.location.lng(),
          // };
          // this.data.address.url = place.url;

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          // this.latitude = place.geometry.location.lat();
          // this.longitude = place.geometry.location.lng();
          // this.zoom = 9;
        });
      });
    });

    this.myFormGroup.get("roleFormControl").valueChanges.subscribe((val) => {
      if (val === 'производство') {
        this.wasNameControlPlaceholder = this.nameControlPlaceholder;
        this.wasNameControlHint = this.nameControlHint;
        this.nameControlPlaceholder = "Название производства";
        this.nameControlHint = "ООО \"Производство потолков\"";
        this.showManufacture = true;
      } else {
        this.nameControlPlaceholder = this.wasNameControlPlaceholder || this.nameControlPlaceholder;
        this.nameControlHint = this.wasNameControlHint || this.nameControlHint;
        this.showManufacture = false;
      }
    });
  }

  setJobType(value) {
    console.log(value);
    this.jobType = value;
  }

  isManufacture() {
    if (this.myFormGroup.value.roleFormControl === "производство") {
      return false;
    } else {
      return null;
    }
  }

  addRecord() {

    this.showForm = false;
    // const name = this.myFormGroup.value.nameFormControl.trim().replace(/\s+/g, ' ').split(' ');
    // console.log('Name: ' + name);
    // console.log(name);

    this.afs.collection(`stoplist`).add({
      nameArr: this.myFormGroup.value.nameFormControl.trim().replace(/\s+/g, ' ').split(' '),
      name: this.myFormGroup.value.nameFormControl.trim(),
      ogrn_or_inn: this.myFormGroup.value.ogrn_or_inn || null,
      approved: this.isManufacture(),
      jobType: this.myFormGroup.value.roleFormControl || null,
      // jobType: this.jobType || null,
      phone: this.myFormGroup.value.phoneFormControl.trim(),
      phone1: this.myFormGroup.value.phoneFormControl1.trim(),
      phone2: this.myFormGroup.value.phoneFormControl2.trim(),
      // passport: this.myFormGroup.value.passportFormControl.replace(/ /g, ''),
      country: this.myFormGroup.value.countryFormControl,
      city: this.stopItemPlace ? (this.stopItemPlace.formatted_address ? this.stopItemPlace.formatted_address : null) : null,
      stopItemPlace: this.stopItemPlace || null,
      comment: this.myFormGroup.value.commentFormControl,
      creatorPhone: this.authService.phoneNumber,
      creatorCompany: this.authService.userDoc.companyName || null,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }).then((doc) => {
      this.newDocId = doc.id;
      this.myFormGroup.reset();
      // setTimeout( () => {
      //   this.router.navigate(['/search']);
      // }, 3000);
      console.log(doc);
    });

  }

  showMorePhoneFields() {
    this.morePhones = true;
  }

}
