import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import * as firebase from 'firebase/app'; // for use firebase.firestore.FieldValue.serverTimestamp()

import { Observable, Subscription } from 'rxjs';

import { LIST } from '../models/stoplistroles.model';
import { COUNTRY_NAME } from '../models/country_name.model';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit, OnDestroy {

  rolesList: string[] = LIST;
  COUNTRY_LIST = COUNTRY_NAME;

  docId: string;
  itemDoc: Observable<any>;

  creatorPhone: string;

  updateState = true;

  docSubscription: Subscription;

  data = {
    name: '',
    jobType: '',
    phone: '',
    phone1: '',
    phone2: '',
    comment: '',
    country: '',
    city: '',
    passport: ''
  };

  myFormGroup = new FormGroup({
    nameFormControl: new FormControl('', Validators.required),
    // passportFormControl: new FormControl(''),

    roleFormControl: new FormControl(''),
    cityFormControl: new FormControl(''),

    phoneFormControl: new FormControl(''),
    phoneFormControl1: new FormControl(''),
    phoneFormControl2: new FormControl(''),

    commentFormControl: new FormControl('', Validators.required),
    countryFormControl: new FormControl('', Validators.required),
  });

  constructor(
    private route: ActivatedRoute,
    private firestore: AngularFirestore
  ) { }

  ngOnInit() {
    this.docId = this.route.snapshot.params['docId'];
    this.itemDoc = this.firestore.doc(`stoplist/${this.docId}`).valueChanges();

    this.docSubscription = this.itemDoc.subscribe((data) => {
      console.log(`Doc data for patchValue ${data.creatorPhone}`);
      this.creatorPhone = data.creatorPhone || '';
      this.data.name = data.name || '';
      this.data.jobType = data.jobType || '';
      this.data.passport = data.passport || '';
      this.data.comment = data.comment || '';
      this.data.country = data.country || '';
      this.data.city = data.city || '';
      this.data.phone = data.phone || '';
      this.data.phone1 = data.phone1 || '';
      this.data.phone2 = data.phone2 || '';

      this.myFormGroup.patchValue({
        nameFormControl: data.name || '',
        roleFormControl: data.jobType || '',
        phoneFormControl: data.phone || '',
        phoneFormControl1: data.phone1 || '',
        phoneFormControl2: data.phone2 || '',
        // passportFormControl: data.passport || '',
        commentFormControl: data.comment || '',
        countryFormControl: data.country || '',
        cityFormControl: data.city || '',
      });

    });

    this.onChanges();

  }

  onChanges() {

    this.myFormGroup.valueChanges.subscribe(val => {
      // console.log(val);
      // console.log(data.name);
      if ((val.nameFormControl === this.data.name) &&
        (val.phoneFormControl === this.data.phone) &&
        (val.phoneFormControl1 === this.data.phone1) &&
        (val.phoneFormControl2 === this.data.phone2) &&
        (val.roleFormControl === this.data.jobType) &&
        // (val.passportFormControl === this.data.passport) &&
        (val.commentFormControl === this.data.comment) &&
        (val.cityFormControl === this.data.city) &&
        (val.countryFormControl === this.data.country)) {
        this.updateState = true;
      } else {
        this.updateState = false;
      }
    });

  }

  updateRecord() {
    // const name = this.myFormGroup.value.nameFormControl.trim().replace(/\s+/g, ' ').split(' ');
    // console.log('Name: ' + name);
    // console.log(name);

    this.firestore.doc(`stoplist/${this.docId}`).update({
      nameArr: this.myFormGroup.value.nameFormControl.trim().replace(/\s+/g, ' ').split(' '),
      name: this.myFormGroup.value.nameFormControl.trim(),
      phone: this.myFormGroup.value.phoneFormControl.trim() || null,
      phone1: this.myFormGroup.value.phoneFormControl1.trim() || null,
      phone2: this.myFormGroup.value.phoneFormControl2.trim() || null,
      jobType: this.myFormGroup.value.roleFormControl || null,
      // passport: this.myFormGroup.value.passportFormControl.replace(/ /g, ''),
      country: this.myFormGroup.value.countryFormControl || null,
      city: this.myFormGroup.value.cityFormControl || null,
      comment: this.myFormGroup.value.commentFormControl || '',
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }).then((doc) => {
      // this.newDocId = doc.id;
      console.log(doc);
      // this.myFormGroup.reset();
    });

  }

  ngOnDestroy() {
    this.docSubscription.unsubscribe();
  }


}
