import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor( public authService: AuthService
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.authService.initUi();
  }

}
