export const COUNTRY_NAME = [
    "Беларусь",
    "Россия",
    "Украина",
    "Казахстан",
    "США",
    "Турция",
    "Германия",
    "Испания",
    "Италия",
    "Польша",
    "Чехия",
    "Латвия",
    "Литва",
    "UAE"
];
