import { Injectable } from '@angular/core';

import firebaseui from './firebaseui-ru/npm__ru';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userName: string;

  uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        console.log('signInSuccessWithAuthResult callback!');
        console.log(authResult);
        this.router.navigate(['/profile']);
        // console.log('signInSuccessWithAuthResult this' + this);
        // this.getUserphoneNumber();
        return false;
      }
    },
    // signInSuccessUrl: '/profile',
    signInOptions: [
      {
        provider: auth.PhoneAuthProvider.PROVIDER_ID,
        recaptchaParameters: {
          type: 'image', // 'audio'
          size: 'normal', // 'invisible' or 'compact'
          badge: 'bottomleft' // 'bottomright' or 'inline' applies to invisible.
        },
        defaultCountry: 'RU', // Set default country to the United Kingdom (+44).
        // For prefilling the national number, set defaultNationNumber.
        // This will only be observed if only phone Auth provider is used since
        // for multiple providers, the NASCAR screen will always render first
        // with a 'sign in with phone number' button.
        // defaultNationalNumber: '1234567890',
        // You can also pass the full phone number string instead of the
        // 'defaultCountry' and 'defaultNationalNumber'. However, in this case,
        // the first country ID that matches the country code will be used to
        // populate the country selector. So for countries that share the same
        // country code, the selected country may not be the expected one.
        // In that case, pass the 'defaultCountry' instead to ensure the exact
        // country is selected. The 'defaultCountry' and 'defaultNationaNumber'
        // will always have higher priority than 'loginHint' which will be ignored
        // in their favor. In this case, the default country will be 'GB' even
        // though 'loginHint' specified the country code as '+1'.
        loginHint: '+7',
        // You can provide a 'whitelistedCountries' or 'blacklistedCountries' for
        // countries to select. It takes an array of either ISO (alpha-2) https://ru.wikipedia.org/wiki/ISO_3166-2 or
        // E164 (prefix with '+') formatted country codes. If 'defaultCountry' is
        // not whitelisted or is blacklisted, the default country will be set to the
        // first country available (alphabetical order). Notice that
        // 'whitelistedCountries' and 'blacklistedCountries' cannot be specified
        // at the same time.
        whitelistedCountries: ['+7', '+375', '+380', 'US', 'TR', 'ES', 'DE', 'CZ', 'PL', 'IT', 'AE', 'IQ', 'IR']
      }
    ]
  };

  firebaseUiInstance: firebaseui.auth.AuthUI;

  user: Observable<firebase.User>;
  phoneNumber: string;
  // shape of doc in users/${userDoc}
  userDoc: any;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user = afAuth.user;
    // console.log('this.user: ' + JSON.stringify(this.user));
    this.user.subscribe(async (data) => {
      console.log(`AuthService recieve user data: ${JSON.stringify(data)}`);
      if (data) {
        this.phoneNumber = data.phoneNumber;

        // check userDoc exists
        let userDocSnapshot = await this.afs.doc(`users/${this.phoneNumber}`).get().toPromise();
        if (userDocSnapshot.exists) {
          this.afs.doc(`users/${data.phoneNumber}`).valueChanges().subscribe(
            (userDoc: any) => {
              console.log("userDoc: ", userDoc);
              // console.log(`userDoc: ${JSON.stringify(userDoc)}`);
              this.userDoc = userDoc;
              this.userName = userDoc.name || '<имя не сохранено в профиле>';
              console.log('Имя пользователя: ', this.userName);
            }
          );
        } else {
          let timer = 0;
          const setIntervalRef = setInterval(async () => {
            timer++;
            console.log("Timer value: ", timer);
            if (timer > 20) {
              console.log('Try 10 times, unref() now...');
              clearInterval(setIntervalRef);
              return;
            }
            // check userDoc exists
            userDocSnapshot = await this.afs.doc(`users/${this.phoneNumber}`).get().toPromise();
            if (userDocSnapshot.exists) {
              console.log(`Get doc on ${timer} time`);
              this.afs.doc(`users/${data.phoneNumber}`).valueChanges().subscribe(
                (userDoc: any) => {
                  console.log("userDoc: ", userDoc);
                  // console.log(`userDoc: ${JSON.stringify(userDoc)}`);
                  this.userDoc = userDoc;
                  this.userName = userDoc.name || '<имя не сохранено в профиле>';
                  console.log('Имя пользователя: ', this.userName);
                }
              );

              clearInterval(setIntervalRef);
              return;
            }

          }, 3000);
        }


      }
    });
    this.firebaseUiInstance = new firebaseui.auth.AuthUI(auth());
  }

  // getUserphoneNumber() {
  //   console.log('phoneNumber property value: ' + this.phoneNumber);
  //   this.user.subscribe((data) => {
  //     console.log(`AuthService recieve user data: ${JSON.stringify(data)}`);
  //     if (data) {
  //       this.phoneNumber = data.phoneNumber;
  //     }
  //   });
  // }

  isAuth() {
    return new Promise((resolve, rejected) => {
      this.afAuth.authState.subscribe(
        (data) => {
          if (data) {
            console.log('authState: ', data);
            resolve(true);
          } else {
            console.log('authState: ', data);
            resolve(false);
          }
        }
      );
    });
  }

  initUi() {
    this.firebaseUiInstance.start('#firebaseui-auth-container', this.uiConfig);
  }

  logout() {

    this.afAuth.auth.signOut().then(() => {
      console.log('Logout success');
      this.router.navigate(['/login']);
    });
  }

}
