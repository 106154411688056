import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private route: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        console.log('run auth guard');

        return this.authService.isAuth().then(
            (value) => {
                if (value) {
                    console.log('You auth! Ok!');
                    return true;
                } else {
                    console.log('Not auth, navigate to /login path');
                    this.route.navigate(['/login']);
                    return false;
                }
            }
        );

        // return this.authService.isAuth().then((data) => {
        //     console.log(data);
        //     if (data) {
        //         return true;
        //     } else {
        //         console.log('Not auth, navigate to /login path');
        //         this.route.navigate(['/login']);
        //         return false;
        //     }
        // }, (reason) => {
        //     console.log(reason);
        //     console.log('Not auth, navigate to /login path');
        //     this.route.navigate(['/login']);
        //     return false;
        // });

        // if (this.authService.phoneNumber) {
        //     return true;
        // } else {
        //     console.log('Not auth, navigate to /login path');
        //     this.route.navigate(['/login']);
        //     return false;
        // }

        // this.authService.user.subscribe((data) => {
        //     if (data) {
        //         console.log('Recieve user data');
        //         return true;
        //     } else {
        //         console.log('Not auth, navigate to /login path');
        //         this.route.navigate(['/login']);
        //         return false;
        //     }
        // });

        // if (this.authService.user) {
        //     console.log('AuthGuard.canActivate() this.authService.user: ', this.authService.user);
        //     return true;
        // } else {
        //     console.log('Not auth, navigate to /login path');
        //     this.route.navigate(['/login']);
        //     return false;
        // }
    }
}
